/*
*
* ********   Gallery Module    ***********
*
*/

.galerie-container {
	margin: 0 auto;
	padding: 0 5px 60px;
}

.galerie {
	max-width: 50em;
	background-color: #7181a2;
	margin: 0 auto 2em;
	box-shadow: 0px 1px 4px black;
	border-radius: 5px;
}

.galerie__header {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	h2 {
		flex: none;
		width: 270px;
		height: 180px;
		margin: 1em;
		line-height: 180px;
		border-radius: 4px;
		text-align: center;
		color: white;
		// border-radius: 5px;
		background-color: #2E384C;
		background-size: cover;
	}
}

.galerie__header__txt {
	flex: 1 1 270px;
	position: relative;
	max-height: 150px;
	margin: 0 1em 1em;
	overflow: hidden;
	color: white;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 30%;
		background-image: linear-gradient(rgba(73, 89, 122, 0), #7181a2);
	}

	&--activated {
		max-height: none !important;
		overflow: visible;

		&::after {
		background-image: none;
		}
	}
}

.galerie-izolace h2 {
	background-image: url('../img/galerie/headers/izolace-vozu.jpg');
}
.galerie-sedadla h2 {
	background-image: url('../img/galerie/headers/sestava-sedadel.JPG');
}
.galerie-toaleta h2 {
	background-image: url('../img/galerie/headers/toaletni-kout.JPG');
}
.galerie-kuchyn h2 {
	background-image: url('../img/galerie/headers/kuchynsky-blok.JPG');
}
.galerie-postel h2 {
	background-image: url('../img/galerie/headers/postel.JPG');
}

.galerie__header__btn {
	width: 100%;
	margin: 0 1em 1em;
	display: flex;
	align-items: center;
	justify-content:center;
}

.galerie__section__btn {
	width: 100%;
	margin: 2em 0 1em;
	text-align: right;
}

.galerie-btn {
	width: 100%;
	padding: 20px;
	border: 0;
	outline: none;
	background-color: rgba(0,0,0,0.1);
	transition: all 0.15s ease-in-out;

	&:hover {
		box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.7);
		transition: all 0.3s ease-in-out;
	}

	&:active {
		padding: 19px;
		transition: all 0.1s ease-in-out;
	}
}

.galerie-btn__svg {	
	width: 22px;
	height: 22px;
	margin: 0 auto;
	background-image: url('../img/icons/sprite.svg');
	background-size: 462px 22px;

	&--toOpen {
		background-position: -374px 0;		
	}

	&--toClose {
		background-position: -396px 0;		
	}
}

.galerie__section {
	display: none;
	padding: 1em 0;
	flex-wrap: wrap;
	justify-content: center;
}

.galerie__section--open {
	display: flex;
}

.gallery__thumbnail {
	box-shadow: 0px 0px 10px rgb(57, 64, 80);
	margin: 3px;
	transition: 0.3s;

	img {
		width: 130px;
	}

	&:hover {
		transition: 0.3s;
		-webkit-filter: brightness(130%)
	}
}

@media (min-width: 630px) {

	.galerie__header__txt {
		max-height: 180px;
		margin: 1em;
	}

	.galerie__section {
		padding: 1em;
	}

	.gallery__thumbnail {
		margin: 6px;

		img {
			width: 180px;
		}
	}
}


@media (min-width: 1000px) {
	
	.galerie {
		max-width: 55em;
	}

	.galerie__header__txt {
		margin: 1em 2em 0 1em;
	}

	.galerie__header__btn {
		margin: 1em;
		height: 180px;
		width: auto;
	}

	.galerie-btn {
		width: auto;
	}
	.galerie__section {
		justify-content: space-between;
	}

	.gallery__thumbnail {
		margin: 6px 0;
	}
		
	.galerie-postel .gallery__thumbnail:last-of-type {
			margin-right: 20.4%;
		}
}
