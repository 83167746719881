//
//
/* **** Contact **** */
//
//
.contact-outer
	padding: 60px 20px 60px
	background: url(../img/index/kontakt_mobile.jpg) 89% 50% / 1000px auto no-repeat black

	@media (min-width: 1000px)
		background: url(../img/index/kontakt.jpg) 55% 50% / 1600px auto no-repeat black

.contact
	max-width: 350px
	margin: 0 auto
	color: white

	&__title
		color: white
		margin: 0 0 30px
		text-transform: uppercase

	&__icon
		position: relative
		padding-left: 2em

	&__icon::before 
		content: ''
		position: absolute
		left: 0
		top: 4px
		width: 1em
		height: 1em
		background-image: url('../img/icons/sprite.svg')
		background-size: 21em 1em

	&__icon--email::before
		background-position:  -2em 0//-36px 0

	&__icon--tel::before
		background-position: -3em 0//-54px 0

	&__icon--loc::before
		background-position: -4em 0//-72px 0

	p img 
		display: inline-block
		width: 18px
		margin-right: 10px

	&__address
		display: flex
		justify-content: space-between
		color: white
		font-style: normal
		text-align: center

		p 
			margin: 0
			line-height: 1.6


	.contact__map
		width: 135px
		height: 85px

.contact-fb
	margin: 50px auto 10px
	text-align: center

.contact-fb-link
	display: block
	width: 56px
	margin: 0 auto
	padding: 8px
	border-radius: 6px
	transition: all 0.2s ease-in-out

	&:hover 
		background-color: black
		transition: all 0.2s ease-in-out

.contact-fb__icon
	width: 40px
	margin: 0 auto


//
// Sub-contact
//
.contact-form-outer
	padding: 60px 15px

.contact-form
	max-width: 500px
	margin: 0 auto
	color: $grey
	
	&__title
		font-size: 20px
		text-align: center
		font-weight: normal
		margin: 0

.form__name, .form__email, .form__zprava
	padding: 16px 10px
	border: 3px solid #62A8B5

	background-color: rgba(0,0,0,0)

.form__name
	float: left
	width: 48%
	margin: 2em 0 1em

.form__email
	float: right
	width: 48%
	margin: 2em 0 1em

.form__zprava
	clear: both
	width: 100%
	height: 150px

.form__sent
	display: block
	width: 125px
	padding: 13px 0
	margin: 2em auto 0
	background-color: #62A8B5
	border: none
	text-align: center
	font-size: 14px
	font-weight: bold
	color: white
	text-transform: uppercase
	//letter-spacing: 0.5px
