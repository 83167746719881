/* Header partial */

.site-header {
	position: relative;

	
	&--fixed {
		@media (min-width: 1000px) {
			position: fixed;
		}
	}
	width: 100%;
	top: 0;
	z-index: 10; // for the box shadow to show
	box-shadow: 0 1px 10px 0 rgba(0,0,0,.2);
	background-color: rgb(58,54,54);
	letter-spacing: 0.5px;
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;

	@media (min-width: 500px) {
		height: 72px;
		display: flex;
		justify-content: space-between;
		align-items: center;

	}
}

.logo {
	display: block;

	padding: 20px 0;
	font-weight: bold;
	color: #62A8B5;
	text-align: center;
	font-size: 20px;
	transition: all 0.3s ease-in-out;

	@media (min-width: 500px) {
		margin: 0 0 0 40px;
		font-size: 22px;
	}

	span {
		font-size: 0.7em;
	}
}
	
.menu-icon {
	position: absolute;
	width: 100%;
	height: 56px;
	padding: 19px 40px;
	line-height: 0; // to fix Firefox bug
	text-align: center;
	color: #3a3636;
	background-color: rgba(255,255,255,0.8);

	button {
		border: none;
		width: 18px;
		height: 18px;
		background: url(../img/icons/sprite.svg);
		background-size: $bgSize18px;
		background-position: -342px 18px;
		padding: 0;
		outline: 0;
		transition: all 0.2s ease-in-out;

		@media (min-width: 500px) {
			background-position: -360px 18px;		
		}
	}
	
	@media (min-width: 500px) {
		position: static;
		width: auto;
		color: white;
		background: none;
	}
	
	@media (min-width: 1000px) {
		display: none;
	}
}

.menu-icon--activated {
	transform: rotateZ(-90deg);
	transition: all 0.2s ease-in-out;
}

.nav__ul {
	display: none;
	flex-wrap: wrap;
	position: absolute;
	top: 126px;
	right: 0;
	width: 100%;
	padding: 0;
	margin: 0 40px 0 0;
	list-style-type: none;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.8);
	margin: 0;
	line-height: 2.6;

	@media (min-width: 500px) {
		top: 100%;
		text-align: right;
	}

	@media (min-width: 1000px) {
		position: static;
		width: auto;
		display: flex;
		margin-right: 40px;
		background-color: transparent;
	}

	li {
		border-bottom: 1px solid #eae4e4;
		width: 100%;

		@media (min-width: 1000px) {
			border-bottom: none;
			width: auto;
		}
	}

	a {
		margin-left: 25px;
		margin-right: 30px;
		padding: 10px;
		color: #3a3636;
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 1px;

		&:hover {
			color: white;	
		}

		@media (min-width: 1000px) {
			color: #efefef;
			margin-right: 0;
		}
	}
}

.nav__ul__goHome {

	&::before {
	content: '';
	display: inline-block;
	margin-right: 8px;
	width: 18px;
	height: 18px;
	background-image: url('../img/icons/sprite.svg');
	background-size: $bgSize18px;
	background-position: -288px 0;

	@media (min-width: 1000px) {
		background-position: -198px 0;
		}
	}
}

.nav__ul--activated {
	display: flex;
}

.site-header--js-narrowed {
	height: 52px;
	transition: all 0.3s ease-in-out;

	.logo {
		font-size: 1.1em !important;
		transition: all 0.3s ease-in-out;
	}
}

.jumbotron {
	position: relative;
	max-width: 1936px;
	height: 300px;
	margin: 0 auto;
	background-size: cover;

	&--index-bg {
		background: url(../img/index/jumbotron_mobile.jpg) 50% 0 / cover no-repeat;

		@media (min-width: 500px), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
			background: url(../img/index/jumbotron_tablet.jpg) 20% 0 / cover no-repeat;
		}
		@media (min-width: 500px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 500px) and (min-resolution: 144dpi){ 
			background: url(../img/index/jumbotron_tablet_2x.jpg) 20% 0 / cover no-repeat;;
		}

		@media (min-width: 1000px) {
			background: url(../img/index/jumbotron.jpg) 20% 50px / 1936px auto no-repeat fixed;
			margin-top: 50px;
			height: 500px;		
		}
		@media (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 1000px) and (min-resolution: 144dpi){ 
			background: url(../img/index/jumbotron_2x.jpg) 20% 50px / 1936px auto no-repeat fixed;
		}
		@media (min-width: 1600px) {
			background-position: 50% 50px;
		}
		@media (min-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 1600px) and (min-resolution: 144dpi){ 
			background-position: 50% 50px;
		}


	}
	
	// &--subpage-bg {
	// 	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(../img/header/1.jpg);
	// 	background-position: center 70%;
	// }
}
