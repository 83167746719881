$break-laptop: 1000px;
$break-desktop: 1640px;
$base-line-height: 1.5;

// icons
$bgSize18px: 378px 18px;
$bgSize14px: 294px 14px;

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
	line-height: $base-line-height;
	font-size: 16px;
	font-family: roboto, sans-serif;
	background-color: #f5f6f9;

	@media (min-width: $break-laptop) {
		font-size: 18px
	}
}

img {
	max-height: 100%;
	display: block;
}

/* Typography */

$highlight-color: #62A8B5;
$grey:	#757684;

h1, h2 {
	font-size: 1.1em;
	text-align: center;
}

// For older IE

.browser-notify {
	position: absolute;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgb(0,0,0);
	color: white;
	zoom: 1;
	filter: alpha(opacity=90);
	opacity: 0.9;
	z-index: 1000;

	&__message {
		font-size: 20px;
		padding-top: 200px;
		max-width: 500px;
		margin: 0 auto;
		text-align: center;
	}
}