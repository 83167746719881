.slogan
	margin: 0
	padding: 35px 15px
	text-align: center
	font-style: italic
	letter-spacing: 0.4px
	color: $grey
	background-color: #EDEEF3

.site-intro
	padding: 60px 20px
	display: flex
	justify-content: center
	flex-wrap: wrap
	background-color: rgb(54,54,54)

.site-intro__title
	flex: 0 1 100%
	margin: 0
	font-size: 1.1em
	font-weight: normal
	color: #D8D9E6

	strong
		display: inline-block
		margin-top: 10px
		color: #62A8B5

.site-intro__img
	flex: 0 1 auto
	width: 125px
	height: 125px
	margin-top: 25px
	border-radius: 50%
	border: 3px solid $highlight-color

.site-intro__txt
	flex: 1 1 400px
	max-width: 345px
	margin: 25px 0 0 0
	text-align: center
	letter-spacing: 0.4px
	color: #D8D9E6

	@media (min-width: 500px)
		margin: 25px 0 0 30px
		flex: 1 1 300px
		text-align: left
	
	@media (min-width: 1000px)
		line-height: 1.8

	@media (min-width: $break-laptop)
		margin: 25px 0 0 50px
		max-width: 400px