.work__title
	position: relative
	margin: 0
	padding: 60px 0 50px
	color: $grey
	text-transform: uppercase

.work__ul
	max-width: 1606px
	margin: 0 auto
	padding: 0 10px 60px

	@media (min-width: 1000px)
		display: flex
		justify-content: center
		padding: 0 2px 120px

.secondary-nav .work__ul
	flex-wrap: wrap

.work__item
	flex: 1 1 0
	max-width: 600px
	margin: 0 auto 20px
	list-style: none
	//flex: 1 1 320px
	border: 1px solid #DFDEDE
	background-color: white

.work__link
	position: relative
	display: block
	//height: 350px
	padding-bottom: 60%

	@media (min-width: 1000px)
		padding-bottom: 80% 
	
	background-size: cover
	background-position: center
	overflow: hidden

.ford-custom__link--bg
	background-image: url(../img/index/ma-prace/ford-custom.jpg)

.ford-2000__link--bg
	background-image: url(../img/index/ma-prace/ford-2000.jpg)

.ford-1996__link--bg
	background-image: url(../img/index/ma-prace/ford-1996.jpg)

.work__link__btn
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	padding: 3px 0
	border: 1px solid $highlight-color
	white-space: nowrap
	text-align: center
	font-weight: bold
	color: white
	background-color: rgba(0,0,0,0.6)

	p 
		position: relative
		display: inline-block
		margin: 0 0 0 34px
		padding: 8px 20px
		border-left: 1px solid $highlight-color
		text-align: center
		font-size: 1.1em
		letter-spacing: 0.5px

		&::before 
			content: ''
			position: absolute
			left: -26px
			top: 50%
			transform: translateY(-50%)
			width: 18px
			height: 18px
			background-image: url('../img/icons/sprite.svg')
			background-size: $bgSize18px
			background-position: 0 18px

.work__txt
	margin: 0
	padding: 2em 20px
	color: #474141
	text-align: center