//
// Styles common for Nabidka, Predchozi-prace, and Gallery page
//

.heading__wrap {
	// width: 1000px;
	margin: 0 auto;
	padding: 80px 0 40px;
	text-align: center;
	// background-color: lavender;
}

.heading--fonts {
	margin: 0;
}

.sub-page-nav {
	width: 1000px;
	margin: 24px auto;

	a {
		font-size: 18px;

		span {
			font-size: 27px;
		}
	}

	&--float {
		float: right;
	}
}

.heading--fonts {
	font-size: 1.8em;
	// color: #212121;
}

.sub-page {
	color: #4E4E4E
}

//
/* Mabidka Page Styles only */
//

.box-shadow {
	box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
}

.flipster--margin {
	margin-bottom: 56px;
	padding-bottom: 4px;
}

.ford-custom__img {
	max-height: 72vh;
	max-width: 1000px;
}

.ford-2000__img {
	max-height: 72vh;
	max-width: 748px;
}

.ford-2000-jumbo__img {
	max-height: 72vh;
	max-width: 640px;

	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
		max-width: 320px;
	}
}

.ford-1996__img {
	max-height: 72vh;
	max-width: 764px;

	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
		max-width: 382px;
	}
}
// Flipster overwrite index page settings

.sub-page__flipster .flipster--flat .flipster__item--past, .ford-custom .flipster--flat .flipster__item--future {
	opacity: 0.4
}

.sub-page__flipster .flipster--flat .flipster__item--past .flipster__item__content {
	transform: scale(0.6)
}

.sub-page__flipster .flipster--flat .flipster__item--future .flipster__item__content {
	transform: scale(0.6)
}

//////


.sub-page__txt {
	padding: 2em 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	background-color: #666B9C;
	background-color: #49597A;

	p {
		width: 590px;
		color: #4E4E4E;
		color: white;
	}
}

// Specifikace

.specs {
	padding: 0 5px 2em;
	
	ul {
		max-width: 530px;
		margin: 1em auto;
		line-height: 1.6em;
	}

	table {
		margin: 0 auto;
	}

	td {
		border: 1px solid #ccc;
		padding: 0.7em 0.7em;
	}
}

.custom-specs, .acs-specs, .cenik {
	margin: 0 auto 2em;
	max-width: 54em;
	padding: 2em 1em;
	background-color: white;

	@media (min-width: 500px) {
		padding: 2em 2em;
	}
}

.custom-specs img {
	width: 100%;
	margin: 4em auto;
}

.acs-specs {

	img {
	width: 100%;
	margin: 0 auto;
	}

	figcaption {
		text-align: center;

		&::before {
			content: '*';
			color: #46e032;
			margin-right: 3px;
		}
	}
}

.cenik {
	img {
		width: 100%
	}
}

.rozdeleni-sekci {
	max-width: 400px;
	margin: 0 auto
}

.cenik  table tr:last-of-type {
	font-weight: bold;
	border-top: 2px solid;
}

// Kam dále

.secondary-nav {
	background-color: white;
	padding-bottom: 60px;

	&--padd-top {
		padding-top: 60px
	}
}

.secondary-nav > p {
	max-width: 530px;
	margin: 0 auto;
	padding: 2em 15px 1.5em;
	text-align: center;
	line-height: 2;
	font-size: 14px;
	color: grey;
}

.sec-nav__item {
		position: relative;
		display: block;
		height: 250px;
		background-size: cover;
		background-position: center;
		box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);

		&:hover {
			box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.7);			
		}
}


.sec-nav-list { 

	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	a {
		width: 400px;
		margin: 8px;
	}
}

.galerie-link-container {
	padding: 8px;
}

.galerie__link--bg {
	max-width: 816px;
	margin: 0 auto;
	background-image: url(../img/ford-custom/111.jpg);
	background-position: center 30%;
}


@media (max-width: 400px) {
	.secondary-nav .work__link__btn p span {
		display: block;
	}
}

/* Predchozi prace */

