//
//
/* **** About **** */
//
//
.about
	position: relative
	max-width: 1600px
	margin: 0 auto
	background-image: url(../img/index/ofirme_mobile.jpg)
	background-size: cover
	// background-attachment: fixed

	@media (min-width: 640px)
		background-image: url(../img/index/ofirme.jpg)
		background-position: 34% 50%
		padding: 55px 0
		height: 620px

	@media (min-width: 640px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 640px) and (min-resolution: 144dpi)
		background-image: url(../img/index/ofirme_2x.jpg)

.about-txt
	color: #EFEFEF
	margin: 0 auto
	padding: 0 20px 60px

	.about__title 
		margin: 0
		padding: 50px 0 1em
		text-transform: uppercase

	@media (min-width: 640px)
		max-width: 590px
		padding: 0 70px 50px	
		background-color: rgba(0,0,0,0.3)

	@media (min-width: 1000px)
		position: absolute
		top: 50%
		right: 50px
		transform: translateY(-50%)

	@media (min-width: 1600px)
		right: 25%
		transform: translate(50%, -50%)

	&__inner
		height: 370px
		padding-right: 25px
		overflow-y: scroll

		p 
			margin: 0 0 1em 0

		&::-webkit-scrollbar
			width: 8px

		&::-webkit-scrollbar-track
			box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3)
			border-radius: 10px

		&::-webkit-scrollbar-thumb
			border-radius: 10px
			box-shadow: inset 0 0 6px rgba(0,0,0,.3)
			background-color: $highlight-color

//
// Sub-about
//

.sub-about__quote
	max-width: 470px
	margin: 0 auto
	padding: 35px 15px
	text-align: center
	font-style: italic
	color: #757684

	&:before, &:after 
		color: #ccc
		font-family: georgia
		font-size: 2em
		line-height: 0.1em
		margin-right: 0.25em
		vertical-align: -0.3em

	&:before 
		content: '\201C'

	&:after
		content: '\201D'
