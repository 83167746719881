/**/
/* ******** Presentation ******** */
/**/
/**/

.presentation-outer
	@media screen and (min-width: $break-desktop)
		padding: 32px 0 0
		
.presentation
	max-width: 1600px
	margin: 0 auto
	position: relative
	background-color: #363636

// Left Pane styles
.upperPane
	position: relative
	z-index: 2 // for the box shadow on bottomPane
	height: 430px
	padding: 60px 15px 10px
	color: #D8D9E6
	color: white
	background: url(../img/index/presentation_mobile.jpg) right 0 / 500px auto no-repeat

	@media (min-width: 500px), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)
		background: url(../img/index/presentation_tablet.jpg) right 0 / 1000px auto no-repeat

	@media (min-width: 1000px)
		height: 460px
		background: url(../img/index/presentation.jpg) center 0 / 1600px auto

	@media (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 1000px) and (min-resolution: 144dpi)
		background: url(../img/index/presentation_2x.jpg) center 0 / 1600px auto

	&__presIntro__p 
		max-width: 370px
		margin: 0 auto

		@media (min-width: 1000px)
			max-width: 420px
			line-height: 1.8

	&__presIntro__p2		
		text-align: center

		@media (min-width: 500px)
			margin: 40px auto 0

.presentation__title
	margin-top: 0
	margin-bottom: 1em
	text-transform: uppercase

.bottomPane
	position: relative
	z-index: 1 // for the box shadow
	padding: 20px 0
	color: white
	background: url(../img/index/presentation_mobile.jpg) right -430px / 500px auto no-repeat
	box-shadow: 0px -2px 6px -2px #000

	@media (min-width: 500px), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)
		background: url(../img/index/presentation_tablet.jpg) right -430px / 1000px auto	no-repeat

	@media (min-width: 500px)
		padding: 50px 0

	@media (min-width: 1000px)
		background: url(../img/index/presentation.jpg) center -460px / 1600px auto


	@media (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 1000px) and (min-resolution: 144dpi)
		background-image: url(../img/index/presentation_2x.jpg)


// Button to open slideshow
.presBtn
	position: relative
	display: block
	height: 60px
	margin: 0 auto
	padding: 0
	border: none
	outline: none
	text-align: left
	background-color: rgba(0, 0, 0, 0.6)
	color: $highlight-color

	&__icon
		display: inline-block
		padding: 0 50px 0 20px
		text-align: left

		&::after
			content: ''
			position: absolute
			right: 20px
			top: 50%
			transform: translateY(-50%)
			width: 18px
			height: 18px
			background-image: url('../img/icons/sprite.svg')
			background-size: $bgSize18px
			background-position: -18px 0

		&--open::after
			background-position: -90px 0 

// Middle Pane styles

.middlePane 
	display: none
	margin: 0 auto
	padding: 1.5em 20px
	list-style: none
	overflow: hidden
	box-shadow: inset 1px 1px 10px black

	&__item
		max-width: 290px
		margin: 0 auto
		padding: 3px 0
		justify-content: center
		align-items: center
		flex-wrap: wrap

		@media (min-width: 640px)
			display: flex
			max-width: 700px

		img
			width: 100%
			border-radius: 2px

			@media (min-width: 640px)
				width: 195px

		p 
			flex: 1 1 300px
			max-width: 470px
			color: #797979
			color: white
			line-height: 1.6

			@media (min-width: 640px)
				margin: 0 0 0 2em

		span 
			font-size: 14px
			color: #62A8B5
			text-transform: uppercase
			font-weight: bold
			letter-spacing: 0.6px
			padding: 3px 10px
			border: 1px solid #62A8B5
			margin-right: 10px

	
